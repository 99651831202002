<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
    :checkValueRefracao=newCheckValueRefracao()
    :additionalInformation="additionalInformation"
    :additionalInformationTooltip="additionalInformationTooltip"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="custom-input-group" >
              <div class="custom-input">
                  <div class="eye-area" >
                    <EyeFill /> D
                  </div>
                  <div class="custom-input eye-box-container">
                    <div class="text-area type-2 with-br" @click="openOptions('esfericoDireito')">
                      <span >Esférico</span>
                      <div>
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control text-center"
                          :value="form.fields.olhoDireitoEsfericoEst"
                          :disabled="!canEdit"
                          @input="el => debounceInput('olhoDireitoEsfericoEst', el.target.value)"
                        />
                        <MultipleEyesOptions
                          v-if="activeEyeBox ==='esfericoDireito' && canEdit"
                          :form="form"
                          activeInput="esfericoDireito"
                          :setActiveInput="setActiveEyeBox"
                          @select="({ key, value }) => updateForm(key, value)"
                          @blur="updateMedicalRecord(form)"
                          :options="
                            form.fields.olhoDireitoEsfericoEst 
                              ? (parseFloat(form.fields.olhoDireitoEsfericoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) 
                              : optionsPositive"
                        />
                      </div>
                    </div>
                    <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoEsfericoEst', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsfericoEst', null)"><p>-</p></button>
                      </div>
                    </div>
                  </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('cilindricoDireito')"
                  >
                    <span>Cilíndrico</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoCilindricoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoCilindricoEst', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='cilindricoDireito' && canEdit"
                        :form="form"
                        activeInput="cilindricoDireito"
                        :setActiveInput="setActiveEyeBox"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="
                          form.fields.olhoDireitoCilindricoEst 
                            ? (parseFloat(form.fields.olhoDireitoCilindricoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) 
                            : optionsNegative"
                        />
                    </div>
                  </div>

                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindricoEst', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindricoEst', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('eixoDireito')"
                  >
                    <span>Eixo</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoEixoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoEixoEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='eixoDireito' && canEdit"
                        :form="form"
                        activeInput="eixoDireito"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 1, 'olhoDireitoEixoEst', 'eixo');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 1, 'olhoDireitoEixoEst', 'eixo')"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('AdicaoDireito')"
                  >
                    <span>Adição</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center border-top-right"
                        :value="form.fields.olhoDireitoAdicaoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoAdicaoEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='AdicaoDireito' && canEdit"
                        :form="form"
                        activeInput="AdicaoDireito"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicaoEst', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicaoEst', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('AVDireito')">
                    <span>AV</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoAVEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoAVEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='AVDireito' && canEdit"
                        :form="form"
                        activeInput="AVDireito"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsAV"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.1, 'olhoDireitoAVEst', 'AV');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.1, 'olhoDireitoAVEst', 'AV')"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2" @click="openOptions('DPDireito')">
                    <span>D.P.</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoDPEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoDPEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='DPDireito' && canEdit"
                        :form="form"
                        activeInput="DPDireito"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsDP"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.1, 'olhoDireitoDPEst', 'DP');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.1, 'olhoDireitoDPEst', 'DP')"><p>-</p></button>
                      </div>
                  </div>
                </div>
              </div>

              <div class="custom-input no-bb">
                  <div class="eye-area">
                  <EyeFill /> E
                  </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('esfericoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEsfericoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEsfericoEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='esfericoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="esfericoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="
                          form.fields.olhoEsquerdoEsfericoEst 
                          ? (parseFloat(form.fields.olhoEsquerdoEsfericoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) 
                          : optionsPositive"
                        />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsfericoEst', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsfericoEst', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>


                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('cilindricoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoCilindricoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoCilindricoEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='cilindricoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="cilindricoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="
                        form.fields.olhoEsquerdoCilindricoEst 
                          ? (parseFloat(form.fields.olhoEsquerdoCilindricoEst.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) 
                          : optionsNegative"
                        />
                    </div>
                  </div>

                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindricoEst', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindricoEst', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>


                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('eixoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEixoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEixoEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='eixoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="eixoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 1, 'olhoEsquerdoEixoEst', 'eixo');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixoEst', 'eixo')"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('AdicaoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center border-bottom-right"
                        :value="form.fields.olhoEsquerdoAdicaoEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoAdicaoEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='AdicaoEsquerdo' && canEdit"
                        :form="form" 
                        activeInput="AdicaoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicaoEst', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicaoEst', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('AVEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoAVEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoAVEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='AVEsquerdo' && canEdit"
                        :form="form"
                        activeInput="AVEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsAV"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.1, 'olhoEsquerdoAVEst', 'AV');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoAVEst', 'AV')"><p>-</p></button>
                      </div>
                  </div>
                </div>


                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2"
                    @click="openOptions('DPEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoDPEst"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoDPEst', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='DPEsquerdo' && canEdit"
                        :form="form"
                        activeInput="DPEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsDP"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.1, 'olhoEsquerdoDPEst', 'DP');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.1, 'olhoEsquerdoDPEst', 'DP')"><p>-</p></button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <button
            class="btn blue-underline button-copy"
            @click="copyEyeValues"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
            >
              <Copy />
          </button>
        </b-row>
        <b-row>
          <b-col class="d-flex flex-row align-items-center"  cols="12">
            <div class="checkbox-wrapper mb-0">
              <Check
                :value="form.fields.usarValoresNaPrescricaoDeOculosEst"
                @input="(checked) => dispatchRefractionToGlasses(checked)"
                :disabled="!canEdit"
              />
              <div>Usar valores na prescrição de óculos</div>
            </div>

            <b-button
              :variant="hasProtocol ? 'primary' : 'outline-primary'"
              class="ml-2"
              
              @click="openProtocolModal"
              >
              <span v-if="protocolsSelectedLength > 0">({{protocolsSelectedLength }})</span>
              Protocolo(s)</b-button
            >

          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <ProtocolsModal
      ref="refEstProtocolModal"
      @onChange="value => saveProtocolsData(value)"
      :protocols="formOculos.fields.protocols  || []"
      :canEdit="canEdit"
      :refractions="refractions"
      :patientBirthday="attendance?.attendance?.patient?.birthday"
    />
  </FormWrapper>
</template>

<script>
import { debounce } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'
import oculosProtocolsMixin from '@/mixins/oculosProtocols/oculosProtocolsMixin'

export default {
  mixins: [attendanceDataMixin('medicalRecordsStore'), oculosProtocolsMixin],

  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    MultipleEyesOptions: () => import('@/components/Attendance/Forms/Components/MultipleEyesOptions'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    Check: () => import('@/components/General/Check'),
    Copy: () => import('@/assets/icons/copy.svg'),
    ProtocolsModal: () => import('@/components/Attendance/Forms/Components/ProtocolsModal'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.refracaoEstatica,
      formOculos: state => state.attendance.form.oculos,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore,
      attendance: state => state.attendance,
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation']),
    ...mapGetters('attendance/form/oculos', ['canOpenProtocolsModal']),
    additionalInformation() {
      return this.hasProtocol ? 'Recomendar produto' : ''
    },  
    additionalInformationTooltip() {
      return this.hasProtocol ? 'Paciente é elegível para o(s) protocolo(s) com base nos resultados do exame de refração. Consulte o "Protocolo" sob os exames na "Refração" ou "Óculos" na seção "Conduta" e escolha a(s) opção(ões) mais adequada(s).' : null
    },
    refractions() {
      return {
        olhoEsquerdoCilindrico: this.form.fields.olhoEsquerdoCilindricoEst,
        olhoDireitoCilindrico: this.form.fields.olhoDireitoCilindricoEst,
        olhoEsquerdoEsferico: this.form.fields.olhoEsquerdoEsfericoEst,
        olhoDireitoEsferico: this.form.fields.olhoDireitoEsfericoEst
      }
    },
    hasProtocol() {
      return this.verifyProtocol(this.refractions, this.attendance?.attendance?.patient?.birthday)
    },
    protocolsSelectedLength() {
      return this.formOculos?.fields?.protocols?.length
    },
  },
  data() {
    return {
      activeEyeBox: String,
      optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
      optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
      optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
      optionsDP: [['3,0', '3,5', '4,0', '4,5'], ['5,0', '5,5', '6,0', '6,5'], ['7,0', '7,5', '8,0', '8,5'], ['9,0', '9,5', '10,0', '10,5']],
      optionsAV: [['20/10', '20/15', '20/20', '20/25', '20/30'], ['20/40', '20/50', '20/70', '20/100', '20/200'], ['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'], ['MM', 'PL', 'SPL']],
    }
  },
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/refracaoEstatica', ['handleFields', 'handleProps', 'useValuesOnGlasses']),
    ...mapActions('attendance/form/oculos', {handleFieldsOculosForm: 'handleFields', handlePropsOculosForm: 'handleProps'}),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    openOptions(input){
      this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    copyEyeValues() {
      this.updateForm(`olhoEsquerdoEsfericoEst`, this.form.fields.olhoDireitoEsfericoEst)
      this.updateForm(`olhoEsquerdoCilindricoEst`, this.form.fields.olhoDireitoCilindricoEst)
      this.updateForm(`olhoEsquerdoEixoEst`, this.form.fields.olhoDireitoEixoEst)
      this.updateForm(`olhoEsquerdoDPEst`, this.form.fields.olhoDireitoDPEst)
      this.updateForm(`olhoEsquerdoAVEst`, this.form.fields.olhoDireitoAVEst)
      this.updateForm(`olhoEsquerdoAdicaoEst`, this.form.fields.olhoDireitoAdicaoEst)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const refracaoEstatica = data.find(el => { return el.type === 'autorrefracao-est'})

      if (!refracaoEstatica?.id) return
      Object.keys(refracaoEstatica.value).map(key => {
        if (refracaoEstatica.value[key]) 
          this.updateForm(`${key}Est`, refracaoEstatica.value[key])
      })
      await this.updateMedicalRecord(this.form)
    },
    dispatchRefractionToGlasses(value) {
      this.updateForm('usarValoresNaPrescricaoDeOculosEst', value)
      this.updateMedicalRecord(this.form)
      this.useValuesOnGlasses()
    },
    changeInputValue(change, amount, key, type){
      if(this.form.fields[key] === '' || this.form.fields[key] === null){
        this.form.fields[key] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[key])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(0)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(0)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'º')
              }
          }

          else if(type === 'DP'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(1)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(1)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'')
              }
            }

          else if(!(isNaN(parseFloat(this.form.fields[key])))){
            if(change === 'increase'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(2)).replace('.', ','))

            } else if(change === 'decrease'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(2)).replace('.', ','))
            }
            if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
              this.updateForm(key, '+'+this.form.fields[key])
            }
        }
        this.updateMedicalRecord(this.form)
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    newCheckValueRefracao() {
      const value = this.attendance?.preConsultation?.medical_records?.find(option => option.type === "autorrefracao-est")
      return Boolean(value)
    },
    updateFormOculos(key, value) {
      this.handleFieldsOculosForm({ key, value })
    },
    saveProtocolsData(value) {
      this.updateFormOculos('protocols', value.protocols)
      if (value.insertPatientData) {
        const patient = this.attendance?.attendance?.patient

        if (patient) {
          this.updateFormOculos('patientData', {
            cpf: patient.cpf,
            birthday: patient.birthday,
            address: patient.endereco
          })
        }
      } else {
        this.updateFormOculos('patientData', null)
      }
      this.updateMedicalRecord(this.formOculos)
    },
    openProtocolModal() {
      if (this.canOpenProtocolsModal) {
        this.$refs.refEstProtocolModal.show()
      } else {
        this.$toast.warning('Para abrir a seleção de protocolos, é preciso adicionar a prescrição de óculos à consulta.')
      }
    }
  },
  watch: {
    'form.fields': {
      handler() {
        if (this.form.fields.usarValoresNaPrescricaoDeOculosEst) {
          this.useValuesOnGlasses()
        }
      },
      deep: true,
    },
  }
  
}
</script>
<style lang="scss" scoped>
.getAutoRefraction{

  &:hover {
    .order-body {
      display: block;
    }
  }
  .order-body {
    display: none;
    background-color: #fff;
    border: 1px solid var(--neutral-200);
    border-radius: 4px;
    padding: 5px 25px;
    position: absolute;
    z-index: 5;
    box-shadow: 10px 5px 5px var(--neutral-200);
  }
}
.eyeValueChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 !important;
  position: relative;
  margin-left: -30px !important;
  z-index: 5;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;

    button {
      height: 20px;
      width: 30px;
      color: var(--greys-60);
      background-color: #fff;
      position: relative !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1.5px solid var(--blue-100) !important;

      p {
        font-weight: 700;
      }

    }
  }
}
.refraction {
  color: var(--blue-500);
  text-decoration: underline;
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: 700;
}
.disabled {
  color: var(--neutral-200);
}
.eye-box-container {
  .eyeValueChange {
    visibility: hidden;
  }
  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}
.custom-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin: 24px 0 16px 0;

  .custom-input {
    flex: 1;
    height: 38px;
    border: 8px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--neutral-300);
    justify-content: center;

    .eye-area {
      width: 60px;
      background-color: var(--neutral-100);
      border-right: 1px solid var(--neutral-300);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--type-active);
        margin-right: 4px;
      }
    }

    &.no-bb {
      border-bottom: 0;

      .eye-area {
        border-radius: 0 0 0 8px;
      }
    }

    .text-area {
      flex: 1;
      line-height: 55px;
      margin-left: 16px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);

      .form-control {
        border: 0 !important;
        border-radius: 0 !important;

        &.with-bbr {
          border-radius: 0 0 8px 0 !important;
        }

        &.with-btr {
          border-radius: 0 8px 0 0 !important;
        }
      }

      &.type-2 {
        position: relative;
        margin-left: 0;
        text-align: center;

        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }
      &.with-br {
        border-right: 1px solid var(--neutral-300) !important;
      }

      &.with-brtr {
        border-radius: 0 8px 0 0 !important;
      }

      &.with-brbr {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }
}
.border-top-right {
  border-radius: 0 8px 0 0 !important;
}
.border-bottom-right {
  border-radius: 0 0 8px 0 !important;
}
</style>
